import React, { useEffect, useRef } from 'react';
import { Height } from '../util/ScreenHeight'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { Divider } from '@mui/material';
import { getId } from '../util/ID'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Adsense } from '@ctrl/react-adsense';


export default function CLanding(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(100)

    const [showEmpty, setShowEmpty] = React.useState(false);
    const [emptyMsg, setEmptyMsg] = React.useState('');
    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const fetchData = () => {
    }

    useEffect(() => {
        fetchData()
    }, []);


    return (
        <Box style={{ flexDirection: 'column', position: 'absolute', width: '100%', top: Height.header, bottom: fullScreen ? 55 : 0, overflowY: 'scroll' }} sx={{}}>
            <section data-bs-version="5.1" className="header2 cid-tdm0ZOX537 mbr-fullscreen mbr-parallax-background" id="header2-0" style={{ minHeight: '75vh' }}>
                
                {localStorage.getItem('noad') !== "1" && <Adsense
                    client="ca-pub-8613274553211553"
                    slot="8189121133"
                    style={{ display: 'block' }}
                    layout="in-article"
                    format="fluid"
                />}

                <div className="mbr-overlay" style={{ opacity: 0.5, backgroundColor: 'rgb(35, 35, 35)' }}></div>

                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-7">
                            <h1 className="mbr-section-title mbr-fonts-style mb-3 display-1"><strong>股票PR值</strong></h1>

                            <p className="mbr-text mbr-fonts-style display-7">全新的選股方式，選擇重要的條件，比較PR值</p>
                            <div className="mbr-section-btn mt-3"><a className="btn btn-success display-4" onClick={e => props.setMenu(2)}>立即使用</a></div>
                        </div>
                    </div>
                </div>
            </section>

            <section data-bs-version="5.1" className="header12 cid-tdmig2e7Sh mbr-fullscreen mbr-parallax-background" id="header12-4">




                <div className="mbr-overlay" style={{ opacity: 0.3, backgroundColor: 'rgb(255, 255, 255)' }}>
                </div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="card col-12 col-md-12 col-lg-9">
                            <div className="card-wrapper">
                                <div className="card-box align-center">
                                    <h1 className="card-title mbr-fonts-style mb-3 display-1">
                                        <strong>PR值是什麼?</strong></h1>
                                    <p className="mbr-text mbr-fonts-style display-7" style={{ textAlign: "left" }}>Percentile Rank又稱為百分等級。股票上每個條件（例如毛利率）的PR值是代表先將此條件按造優到劣排序後，均分成一百等分，此股票會落在第幾個等分中。簡單來說，若某股票的毛利率PR值為 95，即表示該股票的毛利率高於市場上約 95%股票。PR值從99到0。</p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section data-bs-version="5.1" className="header18 cid-tdm7k8FdN1" id="header18-1">






                <div className="align-center container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-10">
                            <h1 className="mbr-section-title mbr-fonts-style mbr-white mb-3 display-1"><strong>綜合分數</strong></h1>

                            <p className="mbr-text mbr-fonts-style mbr-white display-7">自行挑選1-6個指標，將各指標個別PR值加總後的總分即為綜合分數。</p>
                            <div className="mbr-section-btn mt-3"><a className="btn btn-primary display-4" onClick={e => props.setMenu(2)} >立即使用</a></div>
                        </div>
                    </div>
                </div>
            </section>

            <section data-bs-version="5.1" className="features3 cid-tdmskPiOUh" id="features3-8">


                <div className="container">
                    <div className="mbr-section-head">
                        <h4 className="mbr-section-title mbr-fonts-style align-center mb-0 display-2"><strong>特色</strong></h4>

                    </div>
                    <div className="row mt-4">
                        <div className="item features-image сol-12 col-md-6 col-lg-4">
                            <div className="item-wrapper">
                                <div className="item-img">
                                    <img src="assets/images/-2022-08-02-215439-418x548.png" alt="" />
                                </div>
                                <div className="item-content">
                                    <h5 className="item-title mbr-fonts-style display-7"><strong>條件PR值排名</strong></h5>

                                    <p className="mbr-text mbr-fonts-style mt-3 display-7">列出股票重要條件在市場中的PR值，將所選項目的PR值加總後產生的綜合評比分數。</p>
                                </div>
                                <div className="mbr-section-btn item-footer mt-2"><a onClick={e => props.setMenu(2)} className="btn btn-primary item-btn display-7" target="_blank">立即使用 &gt;</a></div>
                            </div>
                        </div>
                        <div className="item features-image сol-12 col-md-6 col-lg-4">
                            <div className="item-wrapper">
                                <div className="item-img">
                                    <img src="assets/images/-2022-08-02-220127-592x755.png" alt="" />
                                </div>
                                <div className="item-content">
                                    <h5 className="item-title mbr-fonts-style display-7"><strong>條件清楚呈現</strong></h5>

                                    <p className="mbr-text mbr-fonts-style mt-3 display-7">點選綜合評比分數即可呈現全部指標的數字；點擊個別指標數字則可顯示該指標歷史資料圖表。</p>
                                </div>
                                <div className="mbr-section-btn item-footer mt-2"><a onClick={e => props.setMenu(2)} className="btn btn-primary item-btn display-7" target="_blank">立即使用
                                    &gt;</a></div>
                            </div>
                        </div>
                        <div className="item features-image сol-12 col-md-6 col-lg-4">
                            <div className="item-wrapper">
                                <div className="item-img">
                                    <img src="assets/images/-2022-08-02-220017-547x683.png" alt="" />
                                </div>
                                <div className="item-content">
                                    <h5 className="item-title mbr-fonts-style display-7"><strong>選股簡單</strong></h5>

                                    <p className="mbr-text mbr-fonts-style mt-3 display-7">只要選擇１－６個重要的條件，立即找出市場名列前茅的股票。<br /></p>
                                </div>
                                <div className="mbr-section-btn item-footer mt-2"><a onClick={e => props.setMenu(2)} className="btn btn-primary item-btn display-7" target="_blank">立即使用
                                    &gt;</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section data-bs-version="5.1" className="pricing2 cid-tdmosiEFz0" id="pricing2-5">




                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6 align-center col-lg-4">
                            <div className="plan">
                                <div className="plan-header">
                                    <h6 className="plan-title mbr-fonts-style mb-3 display-5">
                                        <strong>免費</strong>
                                    </h6>
                                    <div className="plan-price">
                                        <p className="price mbr-fonts-style m-0 display-1"><strong>$0</strong></p>
                                        <p className="price-term mbr-fonts-style mb-3 display-7"><strong>完全免費</strong></p>
                                    </div>
                                </div>
                                <div className="plan-body">
                                    <div className="plan-list mb-4">
                                        <ul className="list-group mbr-fonts-style list-group-flush display-7">
                                            <li className="list-group-item"><span style={{ backgroundColor: 'transparent', fontSize: '1.2rem' }}>隨機顯示部分結果</span><br /></li><li className="list-group-item">無法設定查詢規則
                                            </li><li className="list-group-item">可能會有廣告出現<span style={{ backgroundColor: "transparent", fontSize: '1.2rem' }}>　</span></li>
                                        </ul>
                                    </div>
                                    <div className="mbr-section-btn text-center"><a onClick={e => props.setMenu(2)} className="btn btn-secondary display-4">立即使用</a></div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-12 col-md-6 align-center col-lg-4">
                            <div className="plan">
                                <div className="plan-header">
                                    <h6 className="plan-title mbr-fonts-style mb-3 display-5">
                                        <strong>好友價</strong>
                                    </h6>
                                    <div className="plan-price">
                                        <p className="price mbr-fonts-style m-0 display-1"><strong>$0</strong></p>
                                        <p className="price-term mbr-fonts-style mb-3 display-7"><strong>完全免費</strong></p>
                                    </div>
                                </div>
                                <div className="plan-body">
                                    <div className="plan-list mb-4">
                                        <ul className="list-group mbr-fonts-style list-group-flush display-7">
                                            <li className="list-group-item"><span style={{backgroundColor: "transparent", fontSize: "1.2rem"}}>擁有股票挖土機，股票光明燈，可轉債挖土機的任一序號者</span><br /></li><li className="list-group-item">解開免費版所有限制
                                            </li><li className="list-group-item">無廣告出現</li>
                                        </ul>
                                    </div>
                                    <div className="mbr-section-btn text-center"><a onClick={e => props.setMenu(7)} className="btn btn-secondary display-4">輸入序號</a></div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-12 col-md-6 align-center col-lg-4">
                            <div className="plan">
                                <div className="plan-header">
                                    <h6 className="plan-title mbr-fonts-style mb-3 display-5"><strong>優惠價</strong></h6>
                                    <div className="plan-price">
                                        <p className="price mbr-fonts-style m-0 display-1"><strong>$988</strong></p>
                                        <p className="price-term mbr-fonts-style mb-3 display-7">一次收費，不續扣</p>
                                    </div>
                                </div>
                                <div className="plan-body">
                                    <div className="plan-list mb-4">
                                        <ul className="list-group mbr-fonts-style list-group-flush display-7">
                                            <li className="list-group-item"><span style={{ backgroundColor: 'transparent', fontSize: '1.2rem' }}>顯示全部結果</span><br /></li><li className="list-group-item"><span style={{ backgroundColor: "transparent", fontSize: "1.2rem" }}>解開免費版所有限制</span></li><li className="list-group-item"><span style={{ backgroundColor: "transparent", fontSize: "1.2rem" }}>無廣告出現</span></li>
                                        </ul>
                                    </div>
                                    <div className="mbr-section-btn text-center"><a onClick={e => props.setMenu(7)} className="btn btn-secondary display-4">去購買</a></div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section data-bs-version="5.1" className="content8 cid-tdmz0QBQNJ" id="content8-d">

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="counter-container col-md-12 col-lg-10">
                            <h4 className="mbr-section-title mbr-fonts-style mb-4 display-5">常見問題</h4>
                            <div className="mbr-text mbr-fonts-style display-7">
                                <ul>
                                    <li><strong>購買後如何收到序號？</strong>付款完成後，會收到付款完成通知信，商店訂單編號前8碼即是序號。</li>
                                    <li><strong>購買的序號有時間限制？ </strong>無使用期限。</li>
                                    <li><strong>之後條件會增加？ </strong>會。亦可寫信到jookershop@gmail.com來建議。</li><li><strong>資料都何時更新？</strong>看條件資料來自於何處。跟財報相關的一季更新一次，跟營收相關的一個月更新一次，跟籌碼相關的每日更新一次，跟股價相關的有３０分鐘的延遲更新。</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section data-bs-version="5.1" className="features4 cid-tdm7XKsPku" id="features4-3">

                <div className="mbr-overlay"></div>
                <div className="container">
                    <div className="mbr-section-head">
                        <h4 className="mbr-section-title mbr-fonts-style align-center mb-0 display-2">
                            <strong>其他優質軟體</strong></h4>

                    </div>
                    <div className="row mt-4">
                        <div className="item features-image сol-12 col-md-6 col-lg-3">
                            <div className="item-wrapper">
                                <div className="item-img">
                                    <img src="assets/images/mbr-596x312.jpg" alt="" title="" />
                                </div>
                                <div className="item-content">
                                    <h5 className="item-title mbr-fonts-style display-5"><strong>股票挖土機</strong></h5>
                                    <h6 className="item-subtitle mbr-fonts-style mt-1 display-7">#選股 #回測 #日誌</h6>
                                    <p className="mbr-text mbr-fonts-style mt-3 display-7">簡易的操作介面、快速的搜尋計算、眾多的股票資訊表格將自己的投資理念轉換成幾條簡單的文字規則，讓您從近2000支的股票與ETF中，快速的挖出珍貴的投資標的。</p>
                                </div>
                                <div className="mbr-section-btn item-footer mt-2"><a href="https://stock-digger.com" className="btn item-btn btn-black display-7" target="_blank">立即使用 &gt;</a></div>
                            </div>
                        </div>
                        <div className="item features-image сol-12 col-md-6 col-lg-3">
                            <div className="item-wrapper">
                                <div className="item-img">
                                    <img src="assets/images/mbr-596x397.jpg" alt="" title="" />
                                </div>
                                <div className="item-content">
                                    <h5 className="item-title mbr-fonts-style display-5"><strong>股票光明燈</strong></h5>
                                    <h6 className="item-subtitle mbr-fonts-style mt-1 display-7">
                                        #期望值 #報酬率 #勝率</h6>
                                    <p className="mbr-text mbr-fonts-style mt-3 display-7">股票光明燈找出策略中高勝率／高期望值／高報酬率的股票，提供簡單的進出場燈號，讓投資發揮最大的效益。</p>
                                </div>
                                <div className="mbr-section-btn item-footer mt-2"><a href="https://stock-candle.com/" className="btn item-btn btn-black display-7" target="_blank">立即使用 &gt;</a></div>
                            </div>
                        </div>
                        <div className="item features-image сol-12 col-md-6 col-lg-3">
                            <div className="item-wrapper">
                                <div className="item-img">
                                    <img src="assets/images/mbr-596x397.png" alt="" title="" />
                                </div>
                                <div className="item-content">
                                    <h5 className="item-title mbr-fonts-style display-5"><strong>可轉債挖土機</strong></h5>
                                    <h6 className="item-subtitle mbr-fonts-style mt-1 display-7">#可轉債 #保本</h6>
                                    <p className="mbr-text mbr-fonts-style mt-3 display-7">輕鬆地幫助您找到最安全可靠的可轉債投資機會.<br /></p>
                                </div>
                                <div className="mbr-section-btn item-footer mt-2"><a href="https://cb-digger.com/" className="btn item-btn btn-black display-7" target="_blank">立即使用 &gt;</a></div>
                            </div>
                        </div><div className="item features-image сol-12 col-md-6 col-lg-3">
                            <div className="item-wrapper">
                                <div className="item-img">
                                    <img src="assets/images/mbr-596x335.jpg" alt="" title="" data-slide-to="3" data-bs-slide-to="4" />
                                </div>
                                <div className="item-content">
                                    <h5 className="item-title mbr-fonts-style display-5"><strong>樂透挖土機</strong></h5>
                                    <h6 className="item-subtitle mbr-fonts-style mt-1 display-7">#統計 #AI預測 #集資</h6>
                                    <p className="mbr-text mbr-fonts-style mt-3 display-7">出現, 拖牌, 相鄰, 遺漏, 頭尾
                                        <br />神經網路 RNN LSTM, 隨機森林, 線性回歸, 分類回歸樹
                                        <br />聰明包牌保6中5, 保6中4, 保7中6<br /></p>
                                </div>
                                <div className="mbr-section-btn item-footer mt-2"><a href="https://lottery-digger.com" className="btn item-btn btn-black display-7" target="_blank">立即使用 &gt;</a></div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section data-bs-version="5.1" className="contacts4 cid-tdmKWNvw7d" id="contacts4-e">






                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="text-content col-12 col-md-6">
                            <h2 className="mbr-section-title mbr-fonts-style display-2">
                                <strong>聯絡我們</strong></h2>
                            <p className="mbr-text mbr-fonts-style display-7">有任何的意見或問題歡迎寫信至jookershop@gmail.com</p>
                        </div>
                        <div className="icons d-flex align-items-center col-12 col-md-6 justify-content-end mt-md-0 mt-2 flex-wrap">
                            <a href="https://twitter.com/mobirise" target="_blank">
                                <span className="mbr-iconfont mbr-iconfont-social socicon-facebook socicon"></span>
                            </a>









                        </div>
                    </div>
                </div>

            </section>

            <section data-bs-version="5.1" className="footer7 cid-tdmycxjLiW" id="footer7-9">





                <div className="container">
                    <div className="media-container-row align-center mbr-white">
                        <div className="col-12">
                            <p className="mbr-text mb-0 mbr-fonts-style display-7">
                                © Copyright 2025 喬可小舖生活實業有限公司 - All Rights Reserved
                            </p>
                        </div>
                    </div>
                </div>
            </section>



        </Box>
    );
}
