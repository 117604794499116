const Dollar0_1000 = [
  {id: "0", value: "0"},   
  {id: "5", value: "5"},     
  {id: "10", value: "10"},
  {id: "15", value: "15"},        
  {id: "20", value: "20"},
  {id: "25", value: "25"},    
  {id: "30", value: "30"},
  {id: "35", value: "35"},
  {id: "40", value: "40"},        
  {id: "45", value: "45"},
  {id: "50", value: "50"},
  {id: "60", value: "60"},
  {id: "65", value: "65"},
  {id: "70", value: "70"},
  {id: "75", value: "75"},
  {id: "80", value: "80"},
  {id: "85", value: "85"},    
  {id: "90", value: "90"},
  {id: "95", value: "95"},    
  {id: "100", value: "100"},
  {id: "105", value: "105"},
  {id: "110", value: "110"},
  {id: "115", value: "115"},    
  {id: "120", value: "120"},
  {id: "130", value: "130"},
  {id: "140", value: "140"},                
  {id: "150", value: "150"},
  {id: "200", value: "200"},
  {id: "300", value: "300"},
  {id: "400", value: "400"},    
  {id: "500", value: "500"},    
  {id: "800", value: "800"},
  {id: "1000", value: "1000"},
  {id: "2000", value: "2000"},
  {id: "3000", value: "3000"},
  {id: "4000", value: "4000"},
  {id: "5000", value: "5000"},     
];  

export default Dollar0_1000  