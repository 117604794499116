import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { deepOrange, green } from '@mui/material/colors';
import Badge from '@mui/material/Badge';
import KChart from './KChart';

export default function RecBase(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const openMenu = Boolean(anchorEl);

    const getOvrate = () => {
        return (((props.row.cb2.bprice / props.row.cb2.gprice) - 1) * 100).toFixed(2)
    }

    const clickDetail = () => {
        if (props.setOpen != null) props.setOpen(true)
    }

    const getRankColor = (rank) => {
        if (rank >= 98) return "error"
        else if (rank < 50) return "success"
        else return "secondary"
    }

    const openDetail = (stockNo) => {
        if (props.openDetail != null) {
            props.openDetail(stockNo)
        }
    }

    const openDetail1 = (id, stockNo) => {
        if (props.openDetail1 != null) {
            props.openDetail1(id, stockNo)
        }
    }

    const openDetail2 = (stockNo) => {
        if (props.openDetail2 != null) {
            props.openDetail2(stockNo)
        }
    }

    const getWidth = () => {
        if(props.row.l.length === 1) return "100%"
        else if(props.row.l.length === 2) return "50%"
        else return "33.3%"
    }

    const getWidth1 = () => {
        if(props.row.l.length === 4) return "100%"
        else if(props.row.l.length === 5) return "50%"
        else return "33.3%"
    }

    return (
        <Box sx={{ mt: 0 }} onClick={e => clickDetail()}>
            {/* 今日成交{props.row.price.u}張， */}
            {/* <div>
                <Typography variant="caption" sx={{ p: 1, alignSelf: 'center' }}>轉換價生效日：{props.row.info.csdate}</Typography>
            </div> */}

            <ButtonGroup variant="text"  sx={{ width: '100%', mt: 0, mb: 0, justifyContent: 'center' }}>
                <Button sx={{ width: '66.7%' }} color={theme.palette.mode === "light" ? "secondary":"inherit"} variant={theme.palette.mode === "light"?'contained':"standard"} disableElevation onClick={e => openDetail(props.row.stock)}>
                    <Stack sx={{ justifyContent: 'center', width: "100%" }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >綜合評比</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 68, fontWeight: 'bold' }}>{props.row.score}</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 20, fontWeight: 'bold' }}>PR {props.row.rank}</Typography>
                        {/* <Button fullWidth size="small" disableElevation color={"secondary"} variant="contained" >PR {props.row.rank}</Button> */}
                    </Stack>
                </Button>
                <Button sx={{ width: '33.3%' }} color="inherit" onClick={e => openDetail2(props.row.stock)}>
                    <Stack sx={{ justifyContent: 'center', width: "100%" }}>
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >日線圖</Typography>
                        <KChart prices={props.row.prices} />
                    </Stack>
                </Button>
            </ButtonGroup>

            <Divider variant="fullWidth" sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                }}
            />

            <ButtonGroup variant="text" aria-label="text button group" sx={{ width: '100%', mt: 0, mb: 0, justifyContent: 'center' }}>
                {props.row.l != null && props.row.l.slice(0, 3).map(row => {
                    return <Button sx={{ width: getWidth() }} color="inherit" key={row.name} onClick={e => openDetail1(row.id, props.row.stock)}>
                        <Stack sx={{ justifyContent: 'center', width: "100%" }} key={row.name}>
                            <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >{row.name}</Typography>
                            <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{row.r}</Typography>
                            <Button  fullWidth size="small" disableElevation color={getRankColor(row.rank)} variant="contained" >PR {row.rank}</Button>
                        </Stack>
                    </Button>
                })}
            </ButtonGroup>

            {props.row.l != null && props.row.l.length > 3 && <Divider variant="middle" sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    // margin: 0,
                }}
            />}

            <ButtonGroup variant="text" aria-label="text button group" sx={{ width: '100%', mt: 0, justifyContent: 'center' }}>
                {props.row.l != null && props.row.l.slice(3, 6).map(row => {
                    return <Button sx={{ width: getWidth1() }} color="inherit" key={row.name} onClick={e => openDetail1(row.id, props.row.stock)}>
                        <Stack sx={{ justifyContent: 'center', width: "100%" }}>
                            <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >{row.name}</Typography>
                            <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{row.r}</Typography>
                            <Button fullWidth size="small" disableElevation color={getRankColor(row.rank)} variant="contained" >PR {row.rank}</Button>
                        </Stack>
                    </Button>
                })}
            </ButtonGroup>


        </Box>

    );
}
