import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import { getId } from '../util/ID'
import { Alert, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import AlertD from '../util/AlertD';

export default function LicenseCheckDialog(props) {
    const [open, setOpen] = React.useState(false);
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [age, setAge] = React.useState('0');
    const [name, setName] = React.useState(null);

    const handleChange1 = (event) => {
        setName(event.target.value);
    };

    const handleChange = (event) => {
        setAge(event.target.value);
    };


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        clickLic()
    };


    const clickLic = () => {
        if (name != null) {
            let param = {
                lic: name,
            }

            fetch("/sr/v1/payment/checklic1", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            })
                .then(res => res.json())
                .then(
                    result => {
                        if (result != null && result.r) {
                            setOpen(false);
                            if (props.close != null) props.close(false)
                            if (props.fectchAPIK != null) props.fectchAPIK(22)
                        } else {
                            setAlertMsg("請輸入正確序號")
                            setShowAlert(true)
                        }
                    }
                );
        } else {
            setAlertMsg("請輸入序號")
            setShowAlert(true)
        }
    }



    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    useEffect(() => {
    }, []);

    return (
        <div>
                        {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"序號檢查"}
                </DialogTitle>
                <DialogContent>
                    {/* <Box sx={{ p: 2 }}>
                        
                    </Box> */}

                    <Stack spacing={3} direction="column" sx={{ p: 2 }}>
                        <Typography variant='body2'>請輸入股票挖土機，股票光明燈，可轉債挖土機任一序號即可用優惠價格購買。</Typography>
                        <TextField
                            fullWidth
                            id="outlined-name"
                            label="序號"
                            value={name}
                            onChange={handleChange1}
                        />

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>取消</Button>
                    <Button onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
