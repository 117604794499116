import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Lightbulb, PropaneTwoTone } from '@mui/icons-material';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined'
import Clear from '@mui/icons-material/Clear'
import { getId } from '../util/ID'

import Avatar from '@mui/material/Avatar';
import { deepOrange, green } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import RecBase from './RecBase'
import ButtonGroup from '@mui/material/ButtonGroup';
import KChart from './KChart'
import LinearProgress from '@mui/material/LinearProgress';
import AlertD from '../util/AlertD';

export default function RecItem(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [showOKAlert, setShowOKAlert] = React.useState(false);

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const openMenu = Boolean(anchorEl);


    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openDetail = (dtp) => {
    }

    const getColor = () => {
        if (props.row != null && props.row.price != null) {
            if (props.row.price.i > 0) return "red"
            else if (props.row.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (props.row != null && props.row.price != null) {
            if (props.row.price.i > 0) return "▲" + props.row.price.i + '元'
            else if (props.row.price.i < 0) return "▼" + props.row.price.i + '元'
            else return props.row.price.i + '元'
        } else if (props.row.price != null)
            return props.row.price.i + '元'
        else return '▲0元'
    }




    const clickLove = () => {
        let param = {
            sn: getId(),
            sid: props.row.stock,
        }

        if (localStorage.getItem("rate_lic") != null) {
            param['lic'] = localStorage.getItem("rate_lic")
        }

        fetch("/sr/v1/love/clicklove", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        // if(props.refresh != null) props.refresh()
                        if (props.updateLove != null) props.updateLove(props.row.stock)
                    }
                }
            );
    }

    const handleMenuChange = (newValue) => {
        setAnchorEl(null);
        setOpenSetting(true)
    };

    const askDel = () => {
        setAlertMsg("確定將此筆資料刪除?")
        setShowOKAlert(true)
    }

    const del = () => {
        // if (window.confirm('確定要刪除嗎?')) {
        setShowOKAlert(false)
        let param = {
            id: props.row.id
        }

        if (localStorage.getItem("rate_lic") != null) {
            param['lic'] = localStorage.getItem("rate_lic")
        }

        fetch("/sr/v1/backtest/del", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        if (props.fetchData != null) props.fetchData()
                    } else if (result != null && result.status === -1) {
                        setAlertMsg("序號錯誤，請檢查是否有輸入序號。")
                        setShowAlert(true)
                    }
                }
            );
        // } 
    }


    return (
        <Stack spacing={0} sx={{ m: 1, mb: 0.5, p: 0, border: 2, borderColor: theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main, boxShadow: 0.5, borderRadius: '0px' }}>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {showOKAlert ? <AlertD desc={alertMsg} open={showOKAlert} close={setShowOKAlert} ok={del}></AlertD> : null}

            <Stack direction='row' spacing={0.5} sx={{ p: 0, pr: 1, pt: 0.5 }} >
                <div style={{ flexGrow: 1 }}><Clear onClick={e => askDel()} /></div>
                <Typography variant='caption' sx={{ alignSelf: 'center' }} >{props.row.ts}</Typography>
            </Stack>

            <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} />

            {props.row.tr != null && <KChart id={props.row.id} />}

            {props.row.tr != null && <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} />}

            {props.row.tr != null && <RecBase openDetail4={props.openDetail4} openDetail3={props.openDetail3} openDetail2={props.openDetail2} openDetail1={props.openDetail1} openDetail={props.openDetail} row={props.row} menu={props.menu} setOpen={setOpen} />}
            {props.row.tr == null && <LinearProgress />}

            {/* <Typography sx={{ fontSize: 22, alignSelf: 'center' }}>{"回測中"}</Typography> */}

            <Divider variant="middle" sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    // margin: 0,
                }}
            />
            <Stack sx={{ justifyContent: 'center', width: "100%", p: 1.5 }} spacing={0.5} >
                <Typography variant="subtitle1" fontWeight={"bold"}>回測條件</Typography>
                <Typography fontSize={12}>條件包含{props.row.title}。進場PR{props.row.req.r.mscore}以上，出場PR{props.row.req.r.opr != null?props.row.req.r.opr:props.row.req.r.mscore}以下</Typography>
                <Typography fontSize={12}>本金{props.row.req.capital}元，單筆額度{props.row.req.one_money}元</Typography>
                <Typography fontSize={12}>回測天數{props.row.req.period}天，換股週期{props.row.req.interval}天</Typography>
                {props.row.req != null && props.row.req.r != null && props.row.req.r.rules != null && props.row.req.r.rules.length > 0 && <Typography fontSize={12}>股價{props.row.req.r.rules[0].value1}元-{props.row.req.r.rules[0].value2}元</Typography>}
                <Typography fontSize={12}>權重依序為{props.row.req.r.w1},{props.row.req.r.w2},{props.row.req.r.w3},{props.row.req.r.w4},{props.row.req.r.w5},{props.row.req.r.w6}</Typography>
            </Stack>

            {/* <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} variant="middle" /> */}

        </Stack>

    );
}
