import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import useMediaQuery from '@mui/material/useMediaQuery';

import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import KChart from './KChart';



export default function RateDetail(props) {
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };

    const getRankColor = (rank) => {
        if (rank >= 98) return "error"
        else if (rank < 50) return "success"
        else return "secondary"
    }

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    

    const fetchData = () => {
        let param = {
            sid: props.stock,
        }

        fetch("/sr/v1/app/getOne", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.data != null && result.data.length > 0) {
                        setResult(result.data[0])
                    }
                }
            );
    }


    useEffect(() => {
        fetchData()
    }, []);

    const getColor = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "red"
            else if (result.price.i < 0) return "green"
            else return null
        } else return null
    }

    const getInv = () => {
        if (result != null && result.price != null) {
            if (result.price.i > 0) return "▲" + result.price.i + '元'
            else if (result.price.i < 0) return "▼" + result.price.i + '元'
            else return result.price.i + '元'
        } else if (result.price != null)
            return result.price.i + '元'
        else return '▲0元'
    }

    const getBase = () => {
        if(fullScreen) return 3
        else return 4
    }

    const getPercentBase = () => {
        if(fullScreen) return "33.3%"
        else return "25%"
    }

    const getPercentBase1 = () => {
        if(fullScreen) return "66.7%"
        else return "75%"
    }

    const openDetail1 = (id, sid) => {
        if(props.openDetail1 != null)
            props.openDetail1(id, sid)
    }

    const openDetail2 = (sid) => {
        if(props.openDetail2 != null)
            props.openDetail2(sid)
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ p: 0 }}>
            </DialogTitle>
            <DialogContent sx={{pl:1, pr: 1}}>

                {result != null && <Box display={'flex'}>
                    <Stack direction='row' spacing={0.5} sx={{ flex: 1, p: 0, pl: 0, pt: 0.5, alignSelf: 'center' }} >
                        <Typography sx={{ fontSize: 30, fontWeight: 'bold', alignSelf: 'center' }} onClick={e => setOpen(true)}>{result.company.short_name}</Typography>
                        <Typography variant="caption" sx={{ fontWeight: 'normal' }} onClick={e => setOpen(true)}>{result.stock}</Typography>
                    </Stack>
                    <Stack direction='row' spacing={0.5} sx={{ pr: 1, alignSelf: 'center' }} onClick={e => setOpen(true)}>
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{result.price != null ? result.price.p : "-"}</Typography>
                        <Typography color={getColor()} variant="subtitle2" sx={{ fontWeight: 'normal', alignSelf: 'center' }}>{getInv()}</Typography>
                    </Stack>
                </Box>}

                <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                    style={{
                        border: "none",
                        height: 0.5,
                        margin: 0,
                    }} />


                {result != null && <ButtonGroup variant="text" aria-label="text button group" sx={{ width: '100%', mt: 0, mb: 0, justifyContent: 'center' }}>
                    <Button sx={{ width: getPercentBase1() }} color={theme.palette.mode === "light" ? "secondary":"inherit"} variant={theme.palette.mode === "light"?'contained':"standard"} disableElevation >
                        <Stack sx={{ justifyContent: 'center', width: "100%" }}>

                            <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >綜合評比</Typography>
                            <Typography sx={{ alignSelf: 'center', fontSize: 68, fontWeight: 'bold' }}>{result.score}</Typography>
                            <Typography sx={{ alignSelf: 'center', fontSize: 20, fontWeight: 'bold' }}>PR {result.rank}</Typography>

                            {/* <Typography sx={{ alignSelf: 'center', fontSize: 44, fontWeight: 'bold' }}>{result.score}</Typography>
                            <Button fullWidth size="small" disableElevation color={"inherit"} variant="contained" >PR {result.rank}</Button> */}
                        </Stack>
                    </Button>
                    <Button sx={{ width: getPercentBase() }} color="inherit" onClick={e => openDetail2(result.stock)}>
                        <Stack sx={{ justifyContent: 'center', width: "100%" }}>
                            <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >日線圖</Typography>
                            <KChart prices={result.prices} />
                        </Stack>
                    </Button>
                </ButtonGroup>}

                <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                    style={{
                        border: "none",
                        height: 0.5,
                        margin: 0,
                    }} />

                {result != null && [...Array(Math.ceil(result.l.length / getBase())).keys()].map(i =>
                    <><ButtonGroup variant="text"  sx={{ width: '100%', mt: 0, mb: 0, justifyContent: 'center' }}>
                        {result.l != null && result.l.slice(i * getBase(), i * getBase() + getBase()).map(row => {
                            return <Button sx={{ width: getPercentBase() }} color="inherit" key={row.name} onClick={e => openDetail1(row.id, result.stock)}>
                                <Stack sx={{ justifyContent: 'center', width: "100%" }} key={row.name}>
                                    <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >{row.name}</Typography>
                                    <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{row.r}</Typography>
                                    <Button fullWidth size="small" disableElevation color={getRankColor(row.rank)} variant="contained" >PR {row.rank}</Button>
                                </Stack>
                            </Button>
                        })}
                    </ButtonGroup>
                        <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                            style={{
                                border: "none",
                                height: 0.5,
                                margin: 0,
                            }} />
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button disableElevation variant="contained" color="info" onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}

