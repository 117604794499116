import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function SearchCompany(props) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);

  function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  const oninput = (event, value, reason) => {
    if ((isNumeric(value) && value.length > 1) || (!isNumeric(value) && value.length > 0)) {
      fetch("/sr/v1/info/searchcom?key=" + value)
        .then(res => res.json())
        .then(
          result => {
            setOptions(result);
          });
    }
  }

  const onclose = (event, reason) => {
    setOpen(false);
  }

  const onchange = (event, value) => {
    if (value != null) {
        if(props.setSID != null) props.setSID(value.value)
    //   props.menuChange(value.value)
    }
  }


  return (
    <Autocomplete
      id="stock input"
      open={open}
      onInputChange={oninput}
      onOpen={() => {
        setOpen(true);
      }}
      freeSolo
      autoHighlight
      onChange={onchange}
      onClose={onclose}
      getOptionSelected={(option, value) => option.name === value.name}
      getOptionLabel={option => option.label}
      options={options}
      noOptionsText=""
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label="股票代號或名稱"
          InputProps={{
            ...params.InputProps,
          }}

        />
      )}
    />
  );
}

