import React, { useState, useEffect, useRef } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { getMobileTooltip, getComputerTooltip, getAxisPointer, getMobileDataZoom, getComputerDataZoom } from '../util/GridA'
import useMediaQuery from '@mui/material/useMediaQuery';

import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
        whiteSpace: "nowrap"
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        whiteSpace: "nowrap"
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

export default function BuySellDetail(props) {
    const theme = useTheme();

    const [option, setOption] = React.useState(null);
    const [result, setResult] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
    };


    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);

    useEffect(() => {
    }, []);

    const getROI = (row) => {
        let v = (((row.cprice / row.oprice) - 1) * 100)
        if (v > 0) return <Button sx={{ width: 90 }} variant="outlined" color="error" disableElevation>{v.toFixed(2)}%</Button>
        else return <Button sx={{ width: 90 }} variant="outlined" color="success" disableElevation>{v.toFixed(2)}%</Button>
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{}}>
                股票進出明細
            </DialogTitle>
            <DialogContent sx={{ pl: 1, pr: 1 }}>
                <Typography variant='caption' sx={{ alignSelf: 'center' }} >日誌日期:{props.row.ver}</Typography>
                {props.row != null &&
                    <TableContainer component={Paper} >
                        <Table sx={{}} size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell align="center" >動作</StyledTableCell>
                                    <StyledTableCell align="center" >報酬率</StyledTableCell>
                                    <StyledTableCell>股票</StyledTableCell>
                                    <StyledTableCell align="center">買入日期</StyledTableCell>
                                    <StyledTableCell align="right">買入價格</StyledTableCell>
                                    <StyledTableCell align="right">日誌日股價</StyledTableCell>
                                    <StyledTableCell align="right">數量</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.row.new_stock != null && props.row.new_stock.map((row) => (
                                    <StyledTableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <StyledTableCell align="center">
                                            <Button sx={{ width: 90 }} disableElevation variant="contained" color="error">買進</Button>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {getROI(row)}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row.name} ({row.id})
                                        </StyledTableCell>
                                        <StyledTableCell align="right">{row.ymd}</StyledTableCell>
                                        <StyledTableCell align="right">{row.oprice}元</StyledTableCell>
                                        <StyledTableCell align="right">{row.cprice}元</StyledTableCell>
                                        <StyledTableCell align="right">{row.num}股</StyledTableCell>
                                    </StyledTableRow >
                                ))}
                                {props.row.del_stock != null && props.row.del_stock.map((row) => (
                                    <StyledTableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <StyledTableCell align="center">
                                            <Button sx={{ width: 90 }} disableElevation variant="contained" color="success">賣出</Button>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {getROI(row)}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row.name} ({row.id})
                                        </StyledTableCell>
                                        <StyledTableCell align="right">{row.ymd}</StyledTableCell>
                                        <StyledTableCell align="right">{row.oprice}元</StyledTableCell>
                                        <StyledTableCell align="right">{row.cprice}元</StyledTableCell>
                                        <StyledTableCell align="right">{row.num}股</StyledTableCell>
                                    </StyledTableRow >
                                ))}
                                {props.row.keep_stock != null && props.row.keep_stock.map((row) => (
                                    <StyledTableRow
                                        key={row.id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <StyledTableCell align="center">
                                            <Button sx={{ width: 90 }} disableElevation variant="contained" color="inherit">維持</Button>
                                        </StyledTableCell>
                                        <StyledTableCell align="center">
                                            {getROI(row)}
                                        </StyledTableCell>
                                        <StyledTableCell component="th" scope="row">
                                            {row.name} ({row.id})
                                        </StyledTableCell>
                                        <StyledTableCell align="right">{row.ymd}</StyledTableCell>
                                        <StyledTableCell align="right">{row.oprice}元</StyledTableCell>
                                        <StyledTableCell align="right">{row.cprice}元</StyledTableCell>
                                        <StyledTableCell align="right">{row.num}股</StyledTableCell>
                                    </StyledTableRow >
                                ))}

                            </TableBody>
                        </Table>
                    </TableContainer>}



            </DialogContent>
            <DialogActions>
                <Button disableElevation variant="contained" color="info" onClick={handleClose}>關閉</Button>
            </DialogActions>
        </Dialog>
    );
}

