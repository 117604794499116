import React, { useEffect, useRef } from 'react';
import RecItem from '../rec/RecItem'
import { Height } from '../util/ScreenHeight'
import LoveSelector from './LoveSelector'
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import { getId } from '../util/ID'

import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import { Button } from '@mui/material';
import RateDetail from '../rec/RateDetail'
import DetailChart from '../rec/DetailChart'
import DetailKChart from '../rec/DetailKChart'

export default function LoveContainer(props) {
    const [page, setPage] = React.useState(1);
    const [total, setTotal] = React.useState(100)

    const [result, setResult] = React.useState(null);
    const [menu, setMenu] = React.useState(null)
    const [loading, setLoading] = React.useState(false)
    const [tp, setTp] = React.useState(0)
    const [rules, setRules] = React.useState(null)
    const [openSetting, setOpenSetting] = React.useState(false);
    const [stock, setStock] = React.useState(null);

    const [openSetting1, setOpenSetting1] = React.useState(false);
    const [openSetting2, setOpenSetting2] = React.useState(false);
    const [id, setId] = React.useState(null);
    const [sid, setSid] = React.useState(null);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const fetchData = (ids) => {
        setLoading(true)
        let rids1 = ids

        if (rids1 == null) {
            let ids1 = localStorage.getItem("sr_love_rules")
            if (ids1 != null)
                rids1 = JSON.parse(ids1)
            else rids1 = []
        }

        // if (rids1 != null) {
        let param = {
            id: rids1,
            sn: getId(),
            // rules: rules
        }

        if (localStorage.getItem("rate_lic") != null) {
            param['lic'] = localStorage.getItem("rate_lic")
        }

        if (localStorage.getItem("sr_search_w1") != null) param['w1'] = localStorage.getItem("sr_search_w1")
        if (localStorage.getItem("sr_search_w2") != null) param['w2'] = localStorage.getItem("sr_search_w2")
        if (localStorage.getItem("sr_search_w3") != null) param['w3'] = localStorage.getItem("sr_search_w3")
        if (localStorage.getItem("sr_search_w4") != null) param['w4'] = localStorage.getItem("sr_search_w4")
        if (localStorage.getItem("sr_search_w5") != null) param['w5'] = localStorage.getItem("sr_search_w5")
        if (localStorage.getItem("sr_search_w6") != null) param['w6'] = localStorage.getItem("sr_search_w6")


        fetch("/sr/v1/love/love", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    setLoading(false)

                    if (result != null && result.status === 0) {
                        if (result.data != null && result.data.length > 0) {
                            setTotal(result.total)
                            setResult(result.data)
                        } else {
                            setTotal(0)
                            setResult([])
                        }
                    }
                }
            );
        // }
    }

    useEffect(() => {
        let ids = localStorage.getItem("sr_love_rules")
        if (ids != null) {
            fetchData(JSON.parse(ids))
        }
    }, [page]);



    // useEffect(() => {
    //     if (props.sig != null) {
    //         setPage(1)
    //         setMenu(props.sig)
    //     }
    // }, [props.sig]);


    const updateLove = (sid) => {
        if (sid != null && result != null) {
            let nresult = result.map(r => {
                if (r.stock === sid) {
                    r['islv'] = !r.islv
                    return r
                } else return r
            })
            setResult(nresult)
        }
    }

    const openDetail = (stock) => {
        setStock(stock)
        setOpenSetting(true)
    }

    const openDetail1 = (id, sid) => {
        setId(id)
        setSid(sid)
        setOpenSetting1(true)
    }

    const openDetail2 = (sid) => {
        setSid(sid)
        setOpenSetting2(true)
    }

    return (
        <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
            {openSetting && <RateDetail openDetail1={openDetail1} stock={stock} open={openSetting} close={setOpenSetting}></RateDetail>}
            {openSetting1 && <DetailChart id={id} sid={sid} open={openSetting1} close={setOpenSetting1} />}
            {openSetting2 && <DetailKChart sid={sid} open={openSetting2} close={setOpenSetting2} />}

            <Box style={{ flexDirection: 'column', height: '100%', overflowY: 'hidden' }} sx={{}}>
                <LoveSelector setMenu={setMenu} refresh={fetchData} enableSetting={false} />


                {loading ? <Box sx={{ position: 'absolute', top: Height.header + Height.subheader - 2, width: '100%' }}><LinearProgress color="info" /></Box> : <Grid container spacing={0} sx={{ p: 0.5, width: '100%', bgcolor: 'background.paper', position: 'absolute', top: Height.header + Height.subheader, bottom: props.ismobile || fullScreen ? 100 : 55, overflowY: 'scroll' }}>
                    {result != null && result.map(row => {
                        return <Grid key={row.stock} item xs={12} sm={6} md={4} lg={3}>
                            <RecItem openDetail2={openDetail2} openDetail1={openDetail1} openDetail={openDetail} updateLove={updateLove} tp={tp} key={row.stock} row={row} menu={menu} />
                        </Grid>
                    })}
                    {result != null && result.length === 0 &&
                        <Typography variant="body1" sx={{ width: '100%', textAlign: 'center', alignSelf: 'center', m: 1 }}>很抱歉，無法找到符合條件的股票。</Typography>
                    }

                </Grid>}

                < Box style={{ flexShrink: 0 }} sx={{ position: 'absolute', bottom: props.ismobile || fullScreen ? 60 : 0, width: '100%', mt: 0.5, mb: 0.5, display: 'flex', justifyContent: "center" }}>
                    <Pagination size="small" siblingCount={1} boundaryCount={1} count={total} variant="outlined" shape="rounded" onChange={handlePageChange} />
                </Box>
            </Box >

        </Box >
    );
}
