import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AllStrageRules from '../strage/AllStrageRules'
import TextField from '@mui/material/TextField';

export default function RecSetting(props) {
    const [open, setOpen] = React.useState(false);
    const [sort, setSort] = React.useState(localStorage.getItem('sr_search_sort') != null ? localStorage.getItem('sr_search_sort') : '0');
    const [light, setLight] = React.useState(localStorage.getItem('sr_search_light') != null ? localStorage.getItem('sr_search_light') : '0');
    const [count, setCount] = React.useState(localStorage.getItem('sr_search_count') != null ? localStorage.getItem('sr_search_count') : '0');
    const [nowRows, setNowRows] = React.useState(localStorage.getItem('sr_search_rules') != null ? JSON.parse(localStorage.getItem('sr_search_rules')) : [])

    const [w1, setW1] = React.useState(localStorage.getItem('sr_search_w1') != null ? localStorage.getItem('sr_search_w1') : 1.0);
    const [w2, setW2] = React.useState(localStorage.getItem('sr_search_w2') != null ? localStorage.getItem('sr_search_w2') : 1.0);
    const [w3, setW3] = React.useState(localStorage.getItem('sr_search_w3') != null ? localStorage.getItem('sr_search_w3') : 1.0);
    const [w4, setW4] = React.useState(localStorage.getItem('sr_search_w4') != null ? localStorage.getItem('sr_search_w4') : 1.0);
    const [w5, setW5] = React.useState(localStorage.getItem('sr_search_w5') != null ? localStorage.getItem('sr_search_w5') : 1.0);
    const [w6, setW6] = React.useState(localStorage.getItem('sr_search_w6') != null ? localStorage.getItem('sr_search_w6') : 1.0);


    const clearRules = () => {
        setNowRows([]);
    };


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        localStorage.setItem("sr_search_sort", sort)
        localStorage.setItem("sr_search_light", light)
        localStorage.setItem("sr_search_count", count)
        localStorage.setItem("sr_search_rules", JSON.stringify(nowRows))
        
        localStorage.setItem("sr_search_w1", w1)
        localStorage.setItem("sr_search_w2", w2)
        localStorage.setItem("sr_search_w3", w3)
        localStorage.setItem("sr_search_w4", w4)
        localStorage.setItem("sr_search_w5", w5)
        localStorage.setItem("sr_search_w6", w6)

        setOpen(false);
        if (props.close != null) props.close(false)

        if (props.refresh != null) {
            let ids = localStorage.getItem("sr_rules")
            if (ids != null) {
                props.refresh(JSON.parse(ids))
            }
        }
    };

    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    const updateRule = (id, data, enabled) => {
        if (enabled) {
            const rules = nowRows.filter(item => item.id !== id);
            const finalRules = rules.concat(data)
            setNowRows(finalRules)
        } else {
            const rules = nowRows.filter(item => item.id !== id)
            setNowRows(rules)
        }
    };

    return (
        <div>
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"搜尋設定"}
                    <Typography color="red" variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center', ml: 1 }}>VIP專屬</Typography>
                </DialogTitle>
                <DialogContent sx={{ pl: 1, pr: 1 }}>

                    <Stack spacing={3} direction="column" sx={{ p: 2 }}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">綜合評比</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={sort}
                                label="綜合評比"
                                onChange={e => setSort(e.target.value)}
                            >
                                <MenuItem value={'0'}>{'全部顯示'}</MenuItem>
                                <MenuItem value={'99'}>{'PR99以上'}</MenuItem>
                                <MenuItem value={'98'}>{'PR98以上'}</MenuItem>
                                <MenuItem value={'97'}>{'PR97以上'}</MenuItem>
                                <MenuItem value={'96'}>{'PR96以上'}</MenuItem>
                                <MenuItem value={'95'}>{'PR95以上'}</MenuItem>
                                <MenuItem value={'94'}>{'PR94以上'}</MenuItem>
                                <MenuItem value={'93'}>{'PR93以上'}</MenuItem>
                                <MenuItem value={'92'}>{'PR92以上'}</MenuItem>
                                <MenuItem value={'91'}>{'PR91以上'}</MenuItem>
                                <MenuItem value={'90'}>{'PR90以上'}</MenuItem>
                                <MenuItem value={'80'}>{'PR80以上'}</MenuItem>
                                <MenuItem value={'70'}>{'PR70以上'}</MenuItem>
                                <MenuItem value={'60'}>{'PR60以上'}</MenuItem>
                                <MenuItem value={'50'}>{'PR50以上'}</MenuItem>
                                <MenuItem value={'40'}>{'PR40以上'}</MenuItem>
                                <MenuItem value={'30'}>{'PR30以上'}</MenuItem>
                                <MenuItem value={'20'}>{'PR20以上'}</MenuItem>
                                <MenuItem value={'10'}>{'PR10以上'}</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">個別評比</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={light}
                                label="個別評比"
                                onChange={e => setLight(e.target.value)}
                            >
                                <MenuItem value={'0'}>{'全部顯示'}</MenuItem>
                                <MenuItem value={'99'}>{'PR99以上'}</MenuItem>
                                <MenuItem value={'98'}>{'PR98以上'}</MenuItem>
                                <MenuItem value={'97'}>{'PR97以上'}</MenuItem>
                                <MenuItem value={'96'}>{'PR96以上'}</MenuItem>
                                <MenuItem value={'95'}>{'PR95以上'}</MenuItem>
                                <MenuItem value={'94'}>{'PR94以上'}</MenuItem>
                                <MenuItem value={'93'}>{'PR93以上'}</MenuItem>
                                <MenuItem value={'92'}>{'PR92以上'}</MenuItem>
                                <MenuItem value={'91'}>{'PR91以上'}</MenuItem>
                                <MenuItem value={'90'}>{'PR90以上'}</MenuItem>
                                <MenuItem value={'80'}>{'PR80以上'}</MenuItem>
                                <MenuItem value={'70'}>{'PR70以上'}</MenuItem>
                                <MenuItem value={'60'}>{'PR60以上'}</MenuItem>
                                <MenuItem value={'50'}>{'PR50以上'}</MenuItem>
                                <MenuItem value={'40'}>{'PR40以上'}</MenuItem>
                                <MenuItem value={'30'}>{'PR30以上'}</MenuItem>
                                <MenuItem value={'20'}>{'PR20以上'}</MenuItem>
                                <MenuItem value={'10'}>{'PR10以上'}</MenuItem>
                            </Select>
                        </FormControl>
                        <Stack direction={"row"} spacing={1}>
                            <TextField fullWidth id="w1" label="條件1權重" value={w1} onChange={event => setW1(event.target.value)} />
                            <TextField fullWidth id="w2" label="條件2權重" value={w2} onChange={event => setW2(event.target.value)} />
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                            <TextField fullWidth id="w3" label="條件3權重" value={w3} onChange={event => setW3(event.target.value)} />
                            <TextField fullWidth id="w4" label="條件4權重" value={w4} onChange={event => setW4(event.target.value)} />
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                            <TextField fullWidth id="w5" label="條件5權重" value={w5} onChange={event => setW5(event.target.value)} />
                            <TextField fullWidth id="w6" label="條件6權重" value={w6} onChange={event => setW6(event.target.value)} />
                        </Stack>


                        {/* <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">交易次數</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={count}
                                label="交易次數"
                                onChange={e => setCount(e.target.value)}
                            >
                                <MenuItem value={'0'}>{'0次以上'}</MenuItem>
                                <MenuItem value={'1'}>{'1次以上'}</MenuItem>
                                <MenuItem value={'2'}>{'2次以上'}</MenuItem>
                                <MenuItem value={'3'}>{'3次以上'}</MenuItem>
                                <MenuItem value={'4'}>{'4次以上'}</MenuItem>
                                <MenuItem value={'5'}>{'5次以上'}</MenuItem>
                                <MenuItem value={'6'}>{'6次以上'}</MenuItem>
                                <MenuItem value={'7'}>{'7次以上'}</MenuItem>
                                <MenuItem value={'8'}>{'8次以上'}</MenuItem>
                                <MenuItem value={'9'}>{'9次以上'}</MenuItem>
                                <MenuItem value={'10'}>{'10次以上'}</MenuItem>
                            </Select>
                        </FormControl> */}

                    </Stack>

                    <Divider sx={{ m: 2 }}>
                        <Chip label="股票設定" />
                    </Divider>
                    <AllStrageRules updateRule={updateRule} rows={nowRows} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={clearRules}>清除規則</Button>
                    <Button onClick={handleClose}>取消</Button>
                    <Button disabled={localStorage.getItem("noad") != "1"} onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
