import React, { useEffect, useRef } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AllStrageRules from '../strage/AllStrageRules'
import Checkbox from '@mui/material/Checkbox';
import { Height } from '../util/ScreenHeight'
import ListItemText from '@mui/material/ListItemText';
import FormHelperText from '@mui/material/FormHelperText';
import AlertD from '../util/AlertD';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import * as dayjs from 'dayjs'
import Dollar0_1000 from '../menu/Dollar0_1000'


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 10 + ITEM_PADDING_TOP,
            // width: 250,
        },
    },
};

export default function RecSetting(props) {
    const [open, setOpen] = React.useState(false);
    const [nowRows, setNowRows] = React.useState(localStorage.getItem('sr_search_rules') != null ? JSON.parse(localStorage.getItem('sr_search_rules')) : [])
    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);
    const [pr, setPR] = React.useState("99");
    const [opr, setOPR] = React.useState("99");

    const [totalMoney, setTotalMoney] = React.useState("10");
    const [singleMoney, setSingleMoney] = React.useState("50");
    const [minPrice, setMinPrice] = React.useState("0");
    const [period, setPeriod] = React.useState("365");
    const [intervala, setIntervala] = React.useState("30");
    const [capital, setCapital] = React.useState("");

    const [w1, setW1] = React.useState(localStorage.getItem('sr_snapshot_w1') != null ? localStorage.getItem('sr_snapshot_w1') : 1.0);
    const [w2, setW2] = React.useState(localStorage.getItem('sr_snapshot_w2') != null ? localStorage.getItem('sr_snapshot_w2') : 1.0);
    const [w3, setW3] = React.useState(localStorage.getItem('sr_snapshot_w3') != null ? localStorage.getItem('sr_snapshot_w3') : 1.0);
    const [w4, setW4] = React.useState(localStorage.getItem('sr_snapshot_w4') != null ? localStorage.getItem('sr_snapshot_w4') : 1.0);
    const [w5, setW5] = React.useState(localStorage.getItem('sr_snapshot_w5') != null ? localStorage.getItem('sr_snapshot_w5') : 1.0);
    const [w6, setW6] = React.useState(localStorage.getItem('sr_snapshot_w6') != null ? localStorage.getItem('sr_snapshot_w6') : 1.0);
    const [tYmd, setTYmd] = React.useState(null);

    const [tags, setTags] = React.useState([])
    const [personName, setPersonName] = React.useState([]);

    const handleChange1 = (event) => {
        const {
            target: { value },
        } = event;

        if (value.length > 6) {
            setAlertMsg("最多選擇六個條件")
            setShowAlert(true)
        } else {
            setPersonName(
                typeof value === 'string' ? value.split(',') : value,
            );
        }
    };


    const nameToId = () => {
        return tags.filter(t => personName.includes(t.name)).map(row => row.id)
    }

    const clearRules = () => {
        setNowRows([]);
    };


    const handleClose = () => {
        setOpen(false);
        if (props.close != null) props.close(false)
        // if(props.refresh != null) props.refresh()
    };

    const handleOKClose = () => {
        // localStorage.setItem("sr_backtest_rules", JSON.stringify(nameToId()))
        localStorage.setItem("sr_snapshot_w1", w1)
        localStorage.setItem("sr_snapshot_w2", w2)
        localStorage.setItem("sr_snapshot_w3", w3)
        localStorage.setItem("sr_snapshot_w4", w4)
        localStorage.setItem("sr_snapshot_w5", w5)
        localStorage.setItem("sr_snapshot_w6", w6)

        createBackTest()
    };

    const createBackTest = () => {
        let param = {
            req: {
                id: nameToId(),
                mscore: pr,
                maxc: totalMoney,
                price: singleMoney,
                min_price: minPrice,
                opr : opr,
            }
        }

        if (localStorage.getItem("rate_lic") != null) {
            param['lic'] = localStorage.getItem("rate_lic")
        }

        if (localStorage.getItem("sr_snapshot_w1") != null) param['req']['w1'] = localStorage.getItem("sr_snapshot_w1")
        if (localStorage.getItem("sr_snapshot_w2") != null) param['req']['w2'] = localStorage.getItem("sr_snapshot_w2")
        if (localStorage.getItem("sr_snapshot_w3") != null) param['req']['w3'] = localStorage.getItem("sr_snapshot_w3")
        if (localStorage.getItem("sr_snapshot_w4") != null) param['req']['w4'] = localStorage.getItem("sr_snapshot_w4")
        if (localStorage.getItem("sr_snapshot_w5") != null) param['req']['w5'] = localStorage.getItem("sr_snapshot_w5")
        if (localStorage.getItem("sr_snapshot_w6") != null) param['req']['w6'] = localStorage.getItem("sr_snapshot_w6")

        if (tYmd != null) {
            param['req']['tymd'] = dayjs(tYmd).format('YYYYMMDD')
        }
        if (capital != null && capital != "") {
            param['req']['capital'] = capital
        }

        fetch("/sr/v1/snapshot/create", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        setOpen(false);
                        if (props.close != null) props.close(false)
                        if (props.refresh != null) props.refresh()
                    } else if (result != null && result.status === -1) {
                        setAlertMsg("序號錯誤，請檢查是否有輸入序號。")
                        setShowAlert(true)
                    } else {
                        setAlertMsg("新增失敗，請稍後再嘗試")
                        setShowAlert(true)
                    }
                }
            );
    }


    useEffect(() => {
        if (props.open) setOpen(true)
        else setOpen(false)
    }, [props.open]);


    const updateRule = (id, data, enabled) => {
        if (enabled) {
            const rules = nowRows.filter(item => item.id !== id);
            const finalRules = rules.concat(data)
            setNowRows(finalRules)
        } else {
            const rules = nowRows.filter(item => item.id !== id)
            setNowRows(rules)
        }
    };

    const fectchTags = () => {
        let u = "/sr/v1/app/setting"

        fetch(u, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null) {
                        setTags(result)
                    }
                }
            );
    }

    useEffect(() => {
        fectchTags()
    }, []);

    return (
        <div>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            <Dialog
                fullWidth={true}
                maxWidth={'md'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle id="alert-dialog-title">
                    {"新增日誌"}
                    <Typography color="red" variant="caption" sx={{ fontWeight: 'normal', alignSelf: 'center', ml: 1 }}>VIP專屬</Typography>
                </DialogTitle>


                <DialogContent sx={{ pl: 0.5, pr: 0.5 }}>
                    <DialogContentText sx={{ ml: 2, mr: 2 }}>日誌會將當下篩選出的股票記錄下來，並且跟上－個日誌比對，快速列出股票的增減部位，幫助判斷那些股票需要被賣出，那些股票是可以買進。</DialogContentText>
                    <Stack spacing={3} direction="column" sx={{ p: 2 }}>
                        <FormControl fullWidth sx={{}} focused required>
                            <InputLabel id="demo-simple-select-standard-label">條件</InputLabel>
                            <Select
                                label="條件"
                                labelId="demo-simple-select-standard-label"
                                size="medium"
                                id="demo-multiple-checkbox"
                                multiple
                                value={personName}
                                onChange={handleChange1}
                                // input={<OutlinedInput label="Tag" />}
                                renderValue={(selected) => selected.join(', ')}
                                MenuProps={MenuProps}
                            >
                                {tags.map((row) => (
                                    <MenuItem key={row.id} value={row.name}>
                                        <Checkbox checked={personName.indexOf(row.name) > -1} />
                                        <ListItemText primary={row.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>用來計算綜合PR值</FormHelperText>
                        </FormControl>



                        <FormControl fullWidth required>
                            <InputLabel id="pr-label">進場PR</InputLabel>
                            <Select
                                labelId="pr-label"
                                id="pr"
                                value={pr}
                                label="進場PR"
                                onChange={e => setPR(e.target.value)}
                            >
                                <MenuItem value={'99'}>{'PR99以上'}</MenuItem>
                                <MenuItem value={'98'}>{'PR98以上'}</MenuItem>
                                <MenuItem value={'97'}>{'PR97以上'}</MenuItem>
                                <MenuItem value={'96'}>{'PR96以上'}</MenuItem>
                                <MenuItem value={'95'}>{'PR95以上'}</MenuItem>
                                <MenuItem value={'94'}>{'PR94以上'}</MenuItem>
                                <MenuItem value={'93'}>{'PR93以上'}</MenuItem>
                                <MenuItem value={'92'}>{'PR92以上'}</MenuItem>
                                <MenuItem value={'91'}>{'PR91以上'}</MenuItem>
                                <MenuItem value={'90'}>{'PR90以上'}</MenuItem>
                                <MenuItem value={'80'}>{'PR80以上'}</MenuItem>
                                <MenuItem value={'70'}>{'PR70以上'}</MenuItem>
                                <MenuItem value={'60'}>{'PR60以上'}</MenuItem>
                                <MenuItem value={'50'}>{'PR50以上'}</MenuItem>
                                <MenuItem value={'40'}>{'PR40以上'}</MenuItem>
                                <MenuItem value={'30'}>{'PR30以上'}</MenuItem>
                                <MenuItem value={'20'}>{'PR20以上'}</MenuItem>
                                <MenuItem value={'10'}>{'PR10以上'}</MenuItem>
                            </Select>
                            <FormHelperText></FormHelperText>
                        </FormControl>

                        <FormControl fullWidth required>
                            <InputLabel id="opr-label">出場PR</InputLabel>
                            <Select
                                labelId="opr-label"
                                id="opr"
                                value={opr}
                                label="出場PR"
                                onChange={e => setOPR(e.target.value)}
                            >
                                <MenuItem value={'99'}>{'PR99以下'}</MenuItem>
                                <MenuItem value={'98'}>{'PR98以下'}</MenuItem>
                                <MenuItem value={'97'}>{'PR97以下'}</MenuItem>
                                <MenuItem value={'96'}>{'PR96以下'}</MenuItem>
                                <MenuItem value={'95'}>{'PR95以下'}</MenuItem>
                                <MenuItem value={'94'}>{'PR94以下'}</MenuItem>
                                <MenuItem value={'93'}>{'PR93以下'}</MenuItem>
                                <MenuItem value={'92'}>{'PR92以下'}</MenuItem>
                                <MenuItem value={'91'}>{'PR91以下'}</MenuItem>
                                <MenuItem value={'90'}>{'PR90以下'}</MenuItem>
                                <MenuItem value={'80'}>{'PR80以下'}</MenuItem>
                                <MenuItem value={'70'}>{'PR70以下'}</MenuItem>
                                <MenuItem value={'60'}>{'PR60以下'}</MenuItem>
                                <MenuItem value={'50'}>{'PR50以下'}</MenuItem>
                                <MenuItem value={'40'}>{'PR40以下'}</MenuItem>
                                <MenuItem value={'30'}>{'PR30以下'}</MenuItem>
                                <MenuItem value={'20'}>{'PR20以下'}</MenuItem>
                                <MenuItem value={'10'}>{'PR10以下'}</MenuItem>
                            </Select>
                            <FormHelperText>例如：跌出PR99賣出</FormHelperText>
                        </FormControl>


                        <FormControl fullWidth required>
                            <InputLabel id="totalMoney-label">股票數量</InputLabel>
                            <Select
                                labelId="totalMoney-label"
                                id="totalMoney"
                                value={totalMoney}
                                label="股票數量"
                                onChange={e => setTotalMoney(e.target.value)}
                            >
                                <MenuItem value={'5'}>{'5支以下'}</MenuItem>
                                <MenuItem value={'6'}>{'6支以下'}</MenuItem>
                                <MenuItem value={'7'}>{'7支以下'}</MenuItem>
                                <MenuItem value={'8'}>{'8支以下'}</MenuItem>
                                <MenuItem value={'9'}>{'9支以下'}</MenuItem>
                                <MenuItem value={'10'}>{'10支以下'}</MenuItem>
                                <MenuItem value={'11'}>{'11支以下'}</MenuItem>
                                <MenuItem value={'12'}>{'12支以下'}</MenuItem>
                                <MenuItem value={'13'}>{'13支以下'}</MenuItem>
                                <MenuItem value={'14'}>{'14支以下'}</MenuItem>
                                <MenuItem value={'15'}>{'15支以下'}</MenuItem>
                                <MenuItem value={'20'}>{'20支以下'}</MenuItem>
                                <MenuItem value={'25'}>{'25支以下'}</MenuItem>
                                <MenuItem value={'30'}>{'30支以下'}</MenuItem>
                                <MenuItem value={'35'}>{'35支以下'}</MenuItem>
                                <MenuItem value={'40'}>{'40支以下'}</MenuItem>
                                <MenuItem value={'45'}>{'45支以下'}</MenuItem>
                                <MenuItem value={'50'}>{'50支以下'}</MenuItem>
                            </Select>
                            <FormHelperText>最多持有幾支股票</FormHelperText>
                        </FormControl>

                        <FormControl fullWidth required>
                            <InputLabel id="minPrice-label">股價</InputLabel>
                            <Select
                                labelId="minPrice-label"
                                id="minPrice"
                                value={minPrice}
                                label="股價"
                                onChange={e => setMinPrice(e.target.value)}
                            >
                                {Dollar0_1000.map(value => {
                                    return <MenuItem value={value.id}>{value.value + '元以上'}</MenuItem>
                                })}

                            </Select>
                            <FormHelperText>股價要高於多少才買進</FormHelperText>
                        </FormControl>

                        <FormControl fullWidth required>
                            <InputLabel id="single-money-label">股價</InputLabel>
                            <Select
                                labelId="single-money-label"
                                id="single-money"
                                value={singleMoney}
                                label="股價"
                                onChange={e => setSingleMoney(e.target.value)}
                            >
                                {Dollar0_1000.map(value => {
                                    return <MenuItem value={value.id}>{value.value + '元以下'}</MenuItem>
                                })}

                            </Select>
                            <FormHelperText>股價要低於多少才買進</FormHelperText>
                        </FormControl>

                        <TextField
                            id="capital"
                            label="投入資金"
                            value={capital}
                            onChange={(event) => {
                                setCapital(event.target.value);
                            }}
                            InputProps={{
                                endAdornment: <InputAdornment position="start">元</InputAdornment>,
                            }}
                            helperText="總投資金額"
                        />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker format="YYYY/MM/DD" label="指定日期" value={tYmd} onChange={(newValue) => setTYmd(newValue)} />
                        </LocalizationProvider>

                        <Stack direction={"row"} spacing={1}>
                            <TextField fullWidth id="w1" label="條件1權重" value={w1} onChange={event => setW1(event.target.value)} />
                            <TextField fullWidth id="w2" label="條件2權重" value={w2} onChange={event => setW2(event.target.value)} />
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                            <TextField fullWidth id="w3" label="條件3權重" value={w3} onChange={event => setW3(event.target.value)} />
                            <TextField fullWidth id="w4" label="條件4權重" value={w4} onChange={event => setW4(event.target.value)} />
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                            <TextField fullWidth id="w5" label="條件5權重" value={w5} onChange={event => setW5(event.target.value)} />
                            <TextField fullWidth id="w6" label="條件6權重" value={w6} onChange={event => setW6(event.target.value)} />
                        </Stack>



                    </Stack>


                    {/* 
                    <Divider sx={{ m: 2 }}>
                        <Chip label="股票設定" />
                    </Divider>
                    <AllStrageRules updateRule={updateRule} rows={nowRows} /> */}

                </DialogContent>
                <DialogActions>
                    {/* <Button onClick={clearRules}>清除規則</Button> */}
                    <Button disableElevation variant='contained' onClick={handleClose}>取消</Button>
                    {/* disabled={localStorage.getItem("noad") != "1"} */}
                    <Button disabled={localStorage.getItem("noad") != "1"} disableElevation variant='contained' onClick={handleOKClose} autoFocus>
                        確定
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
