import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { Lightbulb, PropaneTwoTone } from '@mui/icons-material';
import FavoriteBorderOutlined from '@mui/icons-material/FavoriteBorderOutlined'
import Clear from '@mui/icons-material/Clear'
import { getId } from '../util/ID'

import Avatar from '@mui/material/Avatar';
import { deepOrange, green } from '@mui/material/colors';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import RecBase from './RecBase'
import ButtonGroup from '@mui/material/ButtonGroup';
import LinearProgress from '@mui/material/LinearProgress';
import AlertD from '../util/AlertD';
import { Paper } from '@mui/material';

export default function RecItem(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [showOKAlert, setShowOKAlert] = React.useState(false);

    const [showAlert, setShowAlert] = React.useState(false);
    const [alertMsg, setAlertMsg] = React.useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const openMenu = Boolean(anchorEl);


    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const openDetail = (dtp) => {
    }

    const openDetail4 = (row) => {
        if (props.openDetail4 != null) {
            props.openDetail4(row)
        }
    }



    const handleMenuChange = (newValue) => {
        setAnchorEl(null);
        setOpenSetting(true)
    };

    const askDel = () => {
        setAlertMsg("確定將此筆資料刪除?")
        setShowOKAlert(true)
    }


    const del = () => {
        // if (window.confirm('確定要刪除嗎?')) {
        setShowOKAlert(false)
        let param = {
            ts: props.row.ts
        }

        if (localStorage.getItem("rate_lic") != null) {
            param['lic'] = localStorage.getItem("rate_lic")
        }

        fetch("/sr/v1/snapshot/del", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(param)
        })
            .then(res => res.json())
            .then(
                result => {
                    if (result != null && result.status === 0) {
                        if (props.fetchData != null) props.fetchData()
                    } else if (result != null && result.status === -1) {
                        setAlertMsg("序號錯誤，請檢查是否有輸入序號。")
                        setShowAlert(true)
                    }
                }
            );
        // } 
    }

    const getColor = (v) => {
        if(v > 0) return "error"
        else if(v < 0) return "success"
        else return "secondary"
    }

    return (
        <Stack spacing={0} sx={{ m: 1, mb: 0.5, p: 0, border: 2, borderColor: theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main, boxShadow: 0.5, borderRadius: '0px' }}>
            {showAlert ? <AlertD desc={alertMsg} open={showAlert} close={setShowAlert}></AlertD> : null}
            {showOKAlert ? <AlertD desc={alertMsg} open={showOKAlert} close={setShowOKAlert} ok={del}></AlertD> : null}


            <Stack direction='row' spacing={0.5} sx={{ p: 0, pr: 1, pt: 0.5 }} >
                <div style={{ flexGrow: 1 }}><Clear onClick={e => askDel()} /></div>
                <Typography variant='caption' sx={{ alignSelf: 'center' }} >{props.row.ver}</Typography>
            </Stack>

            <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} />

            {/* {props.row.tr != null && <KChart id={props.row.id} />} */}

            <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} />

            <RecBase openDetail4={props.openDetail4} openDetail3={props.openDetail3} openDetail2={props.openDetail2} openDetail1={props.openDetail1} openDetail={props.openDetail} row={props.row} menu={props.menu} setOpen={setOpen} />
            <Divider variant="middle" sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    // margin: 0,
                }}
            />

            <Button size="medium" disableElevation sx={{ mt: 0, borderRadius:0 }} color={getColor(props.row.rev2)} variant='contained' fullWidth>已實現：{props.row.rev2}元</Button>
            <Button size='medium' disableElevation sx={{ mt: 0.1, borderRadius:0 }} color={getColor(props.row.rev1)} variant='contained' fullWidth>未實現：{props.row.rev1}元</Button>

            <Button onClick={e => openDetail4(props.row)} disableElevation sx={{ mt: 0.5, fontWeight: "bold" }} color="secondary" variant='contained' fullWidth>股票進出明細</Button>

            <Divider variant="middle" sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    // margin: 0,
                }}
            />
            <Stack sx={{ justifyContent: 'center', width: "100%", p: 1.5 }} spacing={0.5} >
                <Typography variant="subtitle1" fontWeight={"bold"}>日誌內容</Typography>
                <Typography fontSize={12}>條件包含{props.row.desc}。買進PR{props.row.req.mscore}以上,賣出PR{props.row.req.opr != null ? props.row.req.opr:props.row.req.mscore }以下</Typography>
                <Typography fontSize={12}>股票數量為{props.row.req.maxc}支以下，股價為{props.row.req.min_price}元以上，{props.row.req.price}元以下</Typography>
                <Typography fontSize={12}>總資金{props.row.req.capital!=null?props.row.req.capital:" - "}元。權重依序為{props.row.req.w1},{props.row.req.w2},{props.row.req.w3},{props.row.req.w4},{props.row.req.w5},{props.row.req.w6}</Typography>
            </Stack>

            {/* <Divider sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? "#c1b5c7" : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    margin: 0,
                }} variant="middle" /> */}

        </Stack>

    );
}
