import React, { useEffect, useRef } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import { deepOrange, green } from '@mui/material/colors';
import Badge from '@mui/material/Badge';

export default function RecBase(props) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openSetting, setOpenSetting] = React.useState(false);

    const openMenu = Boolean(anchorEl);

    const getOvrate = () => {
        return (((props.row.cb2.bprice / props.row.cb2.gprice) - 1) * 100).toFixed(2)
    }

    const clickDetail = () => {
        if (props.setOpen != null) props.setOpen(true)
    }

    const get50Color = (rank) => {
        if (rank >= 50) return "error"
        // else if (rank < 50) return "green"
        else return "inherit"
    }

    const get0Color = (rank) => {
        if (rank > 0) return "error"
        else if (rank < 0) return "green"
        else return "inherit"
    }

    const openDetail = (stockNo) => {
        if (props.openDetail != null) {
            props.openDetail(stockNo)
        }
    }

    const openDetail2 = (id) => {
        if (props.openDetail2 != null) {
            props.openDetail2(id)
        }
    }

    const openDetail3 = (id) => {
        if (props.openDetail3 != null) {
            props.openDetail3(id)
        }
    }

    const openDetail4 = (row) => {
        if (props.openDetail4 != null) {
            props.openDetail4(row)
        }
    }

    return (
        <Box sx={{ mt: 0 }} onClick={e => clickDetail()}>

            <ButtonGroup variant="text" aria-label="text button group" sx={{ width: '100%', mt: 0, mb: 0, justifyContent: 'center' }}>
                <Button sx={{ width: '33%' }} color="inherit" key={"3"} >
                    <Stack sx={{ justifyContent: 'center', width: "100%" }} >
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >總數量(支)</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.this_stock.length}</Typography>
                    </Stack>
                </Button>
                <Button sx={{ width: '33%' }} color="inherit" key={"4"} >
                    <Stack sx={{ justifyContent: 'center', width: "100%" }} >
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >未實現%</Typography>
                        <Typography color={get0Color(props.row.roi1)} sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.roi1}</Typography>
                    </Stack>
                </Button>

                <Button sx={{ width: '33%' }} color="inherit" key={"6"} >
                    <Stack sx={{ justifyContent: 'center', width: "100%" }} >
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >已實現%</Typography>
                        <Typography color={get0Color(props.row.roi2)} sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.roi2}</Typography>
                    </Stack>
                </Button>
            </ButtonGroup>

            <Divider variant="middle" sx={{ bgcolor: (theme) => theme.palette.mode === "light" ? theme.palette.primary.main : theme.palette.secondary.main }}
                style={{
                    border: "none",
                    height: 0.5,
                    // margin: 0,
                }}
            />

            <ButtonGroup variant="text" aria-label="text button group" sx={{ width: '100%', mt: 0, mb: 0, justifyContent: 'center' }}>
                <Button sx={{ width: '33%' }} color="inherit" key={"1"} >
                    <Stack sx={{ justifyContent: 'center', width: "100%" }} >
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >不異動(支)</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.keep_stock.length}</Typography>
                        {/* <Button fullWidth size="small" disableElevation color="secondary" variant="contained" onClick={e => openDetail2(props.row.ts)} >維持明細</Button> */}
                    </Stack>
                </Button>
                <Button sx={{ width: '33%' }} color="inherit" key={"5"} >
                    <Stack sx={{ justifyContent: 'center', width: "100%" }} >
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >買進數量(支)</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.new_stock.length}</Typography>
                        {/* <Button fullWidth size="small" disableElevation color="secondary" variant="contained" onClick={e => openDetail3(props.row.ts)}>新買明細</Button> */}
                    </Stack>
                </Button>
                <Button sx={{ width: '33%' }} color="inherit" key={"2"} >
                    <Stack sx={{ justifyContent: 'center', width: "100%" }} >
                        <Typography variant="caption" sx={{ alignSelf: 'center', flexGrow: 1 }}  >賣出數量(支)</Typography>
                        <Typography sx={{ alignSelf: 'center', fontSize: 30, fontWeight: 'bold' }}>{props.row.del_stock.length}</Typography>
                        {/* <Button fullWidth size="small" disableElevation color="secondary" variant="contained" onClick={e => openDetail4(props.row.ts)}>賣掉明細</Button> */}
                    </Stack>
                </Button>
            </ButtonGroup>




        </Box>

    );
}
